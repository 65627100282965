<template>
    <section class="content">
        <div class="invoice p-3 mb-3">
            <!-- title row -->
            <div class="row">
                <div class="col-12">
                    <h4>
                        <i class="fa fa-truck"></i> Penjemputan
                        <small class="float-right"><a href="#" v-on:click="selectWarehouse">Pilih Alamat <i class="fa fa-map"></i></a></small>
                    </h4>
                </div>
                <!-- /.col -->
            </div>
            <!-- info row -->
            <div class="row invoice-info">
                <div class="col-sm-3 invoice-col">
                    Dari
                    <address>
                        <strong>{{sender.senderName}}</strong><br>
                        Telepon: {{sender.senderTel}}<br>
                    </address>
                </div>
                <!-- /.col -->
                <div class="col-sm-3 invoice-col">
                    Alamat
                    <address>
                        {{ sender.addr_detail }}<br>
                        {{ sender.kecamatan }}<br>
                        {{ sender.kota }}<br>
                    </address>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <div class="card card-info">
            <div class="card-header" id="undefined_caption">
                <h3 class="card-title">Data Pesanan</h3>
                <div class="box-tools card-tools toolbar">                    
                    <button type="button" :disabled="submitting" v-on:click="submitOrder" class="btn btn-primary" title="Submit"><i class="fas fa-check"></i> Submit Pesanan <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                </div>
            </div>

            <div class="card-body">   
            <div class="text-rightx"><button type="button" v-on:click="addItem" data-action="create" class="btn btn-info" title="Buat Baru"><i class="fas fas fa-plus"></i> Tambah Item</button></div>  
                <table class="table table-hover" ref="tableorder">
                    <thead>
                        <tr>
                            <th>Nama</th>
                            <th>Telepon</th>
                            <th>Alamat</th>
                            <th>Berat</th>
                            <th>Nilai</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody @click="handleClick">
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="bg-secondary p-3 mb-3"><i class="fa fa-info"></i> Informasi Penerima</div>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Nama</label>
                                    <input type="hidden" name="row_id" value="" />
                                    <input id="username" class="form-control" v-model="form.name" type="text" required="required" name="username" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Telepon</label>
                                    <input id="name" pattern="[0-9]{7,}" formnovalidate class="form-control" v-model="form.phone" type="text" required="required" name="i_name" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Kota / Kecamatan</label>
                                    <v-select label="name" :filterable="false" :options="addrOptions"  v-model="form.addr_id" :reduce="opt => opt.id" @search="onSearch">
                                        <template slot="no-options">
                                          Ketik alamat...
                                        </template>
                                        <template slot="option" slot-scope="option">
                                          <div class="d-center">
                                            {{ option.lokasi }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                          <div class="selected d-center">
                                            {{ option.lokasi }}
                                          </div>
                                        </template>
                                      </v-select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Alamat</label>
                                    <textarea class="form-control" v-model="form.addr_detail" required="required"></textarea>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Berat</label>
                                    <input pattern="[0-9]+([,\.][0-9]+)?" class="form-control" v-model="form.weight" type="text" required="required" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Nilai Paket / COD</label>
                                    <currency-input class="form-control" currency="IDR" v-model="form.cod_val" :precision="0" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Status</label>
                                   <div><toggle-button v-model="form.cod" :value="form.cod" :width="80"
               :labels="{checked: 'COD ', unchecked: ' NON COD '}" /></div>

                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Tambah</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- form select pickup address per shipper id -->
        <Warehouse :show="show" v-model="sender" />
        
    </section>
    <!-- /.content -->
</template>
<script>
import Vue from "vue";
import { createTable, authFetch } from "@/libs/hxcore";
import Warehouse from "@/dialog/Warehouse";
import Swal from "sweetalert2";
import $ from "jquery";
import ToggleButton from "vue-js-toggle-button";
import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "vue-select/dist/vue-select.css";

Vue.use(ToggleButton);
//var tbl = null;
export default {
  name: "Formorder",
  data() {
    return {
      errors: [],
      method: "POST",
      roles: "",
      groups: [],
      addrOptions: [],
      show: false,
      senderAddrOptions: [],
      formTitle: "Tambah Pesanan",
      form: {
        name: "",
        phone: "",
        addr_id: "",
        addr_text: "",
        addr_detail: "",
        cod_val: null,
        weight: 1,
        cod: true,
      },
      submitting: false,
      sender: {},
    };
  },
  components: {
    vSelect,
    maskedInput,
    CurrencyInput,
    Warehouse,
  },
  created() {
    authFetch("/order/address/1").then((res) => {
      res.json().then((json) => {
        //get default address
        if (json.success) {
          this.sender = json.data;
          this.addrOptions.push({
            id: json.data.addr_id,
            lokasi: json.data.kota + " / " + json.data.kecamatan,
          });
        }
      });
    });
  },
  //components: {ToggleButton},
  methods: {
    onSearch(search, loading) {
      if (search.length > 3) {
        loading(true);
        //this.search(loading, search, this);
        var vm = this;
        authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
          res.json().then((json) => (vm.addrOptions = json.items));
          loading(false);
        });
      }
    },
    addItem: function () {
      const e = this.$refs;
      $(e.formDialog).modal("show");
    },
    selectWarehouse: function () {
      this.show = !this.show;
      console.log("click", this.show);
    },
    handleClick(e) {
      console.log(e.target);
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "view") {
        } else if (e.target.dataset.action == "remove") {
          Swal.fire({
            title: "Hapus data?",
            showCancelButton: true,
            confirmButtonText: `Hapus`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch("/order/" + e.target.dataset.id, {
                method: "DELETE",
                body: "id=" + e.target.dataset.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.tbl.api().ajax.reload();
                });

              //Swal.fire('Hapus!', '', 'success')
            }
          });
        }
        //this.$router.push({ path: '/permit/' + e.target.dataset.id })
        console.log(e.target);
        return false;
      }
    },
    submitOrder: function () {
      Swal.fire({
        title: "Submit Pesanan?",
        showCancelButton: true,
        confirmButtonText: `Submit`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.submitting = true;
          authFetch("/order/submit", {
            method: "POST",
          })
            .then((res) => {
              this.submitting = false;
              return res.json();
            })
            .then((js) => {
              this.tbl.api().ajax.reload();
              if (js.success)
                Swal.fire(
                  "Proses Berhasil",
                  "Data pesanan telah disubmit.",
                  "success"
                );
              else Swal.fire("Proses gagal", js.msg, "error");
            });
        }
      });
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var formData = this.form;
      formData.cod = formData.cod ? 1 : 0;
      var data = Object.keys(formData)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(formData[key])
        )
        .join("&");
      var urlSubmit = "/order";
      if (this.method == "PUT") urlSubmit = "/order/" + this.form.id;
      alert(data);
      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.tbl.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    this.tbl = createTable(e.tableorder, {
      title: "Daftar Pesanan",
      serverSide: false,
      lengthChange: false,
      filter: false,
      frame: false,
      ajax: "/order",
      paramData: function (d) {
        d.pickup = 0;
      },
      selectedRowClass: "",
      frame: false,
      lengthChange: false,
      filter: false,
      columns: [
        { data: "destName" },
        { data: "destTel" },
        { data: "destCity" },
        { data: "weight" },
        { data: "codValue" },
        { data: "codValue" },
      ],
      rowCallback: function (row, data) {
        var strAction =
          `<div class="btn-group">
                    <button type="button" class="btn btn-sm btn-outline-danger" data-action="view" data-id="` +
          data.id +
          `"><i class="fas fa-eye"></i> Lihat detail</button>
                    <button type="button" class="btn btn-sm btn-danger" data-action="remove" data-id="` +
          data.id +
          `"><i class="fas fa-trash"></i> Hapus</button>
                  </div>`;
        $("td:eq(5)", row).html(strAction);
      },
    }); /*.fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>'
        })*/
  },
};
</script>